@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Prompt";
	src: url(fonts/Prompt.ttf);
}
@font-face {
	font-family: "RobotoMono";
	src: url(fonts/RobotoMono.ttf);
}

@font-face {
	font-family: "Missing";
	src: url(fonts/Missing.ttf);
}

@font-face {
	font-family: "Micro";
	src: url(fonts/Micro.ttf);
}

* {
	font-family: "Prompt";
	outline: none;
	color: #fff;
	cursor: url("../public/images/mouse.svg"), auto;
}

a,
button {
	@apply cursor-pointer;
}

.button {
	@apply cursor-pointer shadow-[1px_1px_0_0_#000] active:shadow-none bg-purplePrimary border-[1px] border-black active:translate-x-0.5 active:translate-y-0.5;
}
.button-lg {
	@apply cursor-pointer shadow-[3px_3px_0_0_#000] active:shadow-none bg-purplePrimary border-[1px] border-black active:translate-x-0.5 active:translate-y-0.5;
}
.button-no-active {
	@apply shadow-[1px_1px_0_0_#000] border-black;
}
.button-lg-no-active {
	@apply shadow-[3px_3px_0_0_#000] border-black;
}
.center-absolute {
	@apply absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}
.owl-theme .owl-nav [class*="owl-"].disabled {
	display: none;
}
.owl-theme .owl-nav [class*="owl-"] {
	position: absolute;
	top: 0%;
	height: 100%;
	width: 70px;
	background: rgba(50, 50, 50, 0.56) !important;
	margin: 0 !important;
	border-radius: 0 !important;
	transition: 0.5s;
}
.owl-theme .owl-nav [class*="owl-"] span {
	color: #000;
	font-size: 4rem;
}
.owl-theme .owl-nav .owl-next {
	right: 0%;
}
.owl-theme .owl-nav .owl-prev {
	left: 0%;
}
.owl-carousel .owl-stage-outer {
	overflow: visible !important;
}
.owl-theme {
	overflow: hidden;
}

.drop-shadow-active {
	filter: drop-shadow(5px 0px 0px #3f2062) drop-shadow(-5px 0px 0px #3f2062)
		drop-shadow(0px 5px 0px #3f2062) drop-shadow(0px -5px 0px #3f2062);
}

header {
	background: url("../public/images/header.webp") no-repeat;
	background-size: cover;
	background-position: center;
}
header:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	/* background: #20122671; */
	z-index: -1;
}

.base {
	width: 50px;
	height: 50px;
	background-color: rgb(0, 0, 0);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	mix-blend-mode: hard-light;
}

.hole {
	width: 20px;
	height: 20px;
	background-color: gray;
	clip-path: polygon(0 0, 0% 100%, 100% 50%);
}
.cursor-pointer > * {
	cursor: url(../public/images/mouse-select.svg), pointer;
}
.need-help {
	position: relative;
	z-index: 10;
	background-image: url(../public/images/need-help.webp);
}
.need-help::after {
	/* content: ""; */
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgb(0, 0, 0, 0.2);
	z-index: 2;
}
.about {
	background-image: url(../public/images/about-bg.webp);
}
.about::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1;
}

input[type="color"]::-moz-color-swatch {
	border: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
	border-radius: 0;
}

input[type="color"]::-webkit-color-swatch {
	border: none;
}
