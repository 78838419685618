:root {
	--width: 350px;
	--height: calc(var(--width) / 26 * 4);
}

@media (max-width: 768px) {
	:root {
		--width: 200px;
		--height: calc(var(--width) / 26 * 4);
	}
}

.wolf {
	display: grid;
	grid-template-columns: repeat(26, 1fr);
	width: var(--width);
	animation: wolf 4s steps(10) infinite;
	position: relative;
	margin: 50px 0;
}
@keyframes wolf {
	0% {
		left: 0;
		transform: translateX(0) scaleX(1);
	}
	50% {
		left: 100%;
		transform: translateX(-100%) scaleX(1);
	}
	51% {
		left: 100%;
		transform: translateX(-100%) scaleX(-1);
	}
	100% {
		left: 0;
		transform: translateX(0) scaleX(-1);
	}
}
.tails {
	grid-column: span 8;
	display: flex;
	animation: tail-container 1s steps(3) infinite;
}
@keyframes tail-container {
	0% {
		transform: translateX(0%);
	}
	50% {
		transform: translateX(50%);
	}
	100% {
		transform: translateX(0%);
	}
}
.tail-container {
	/* grid-column: span 8;
    display: flex; */
	display: flex;
	transform-origin: bottom right;
	animation: tail 1s steps(3) infinite;
}
@keyframes tail {
	0% {
		transform: rotate(45deg);
	}
	50% {
		transform: rotate(-45deg);
	}
	100% {
		transform: rotate(45deg);
	}
}
.tail-container > div {
	width: 100%;
}
.tail-container {
	width: 100%;
}
.block-div {
	background: #7e3dd9;
	height: var(--height);
	grid-column: span 4;
}
.empty {
	height: var(--height);
	grid-column: span 4;
}
.block-half {
	background: #7e3dd9;
	height: var(--height);
	grid-column: span 2;
}
.tail {
	clip-path: polygon(100% 0, 0 0, 100% 100%);
}
.ear {
	clip-path: polygon(100% 0, 100% 100%, 0 100%);
}
.face {
	clip-path: polygon(100% 0, 0 0, 0 100%);
}
.back-leg {
	transform-origin: top;
	animation: back-leg 1s steps(3) infinite;
}
.front-leg {
	transform-origin: top;
	animation: front-leg 1s steps(3) infinite;
}
@keyframes back-leg {
	0% {
		transform: translateX(0%);
	}
	50% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}
@keyframes front-leg {
	0% {
		transform: translateX(0%);
	}
	50% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}
/* @keyframes back-leg {
    0% {
        transform: skew(30deg);
    }
    50% {
        transform: skew(-30deg);
    }
    100% {
        transform: skew(30deg);
    }
}
@keyframes front-leg {
    0% {
        transform: skew(-30deg);
    }
    50% {
        transform: skew(30deg);
    }
    100% {
        transform: skew(-30deg);
    }
} */

.back {
	transform-origin: left;
	animation: back 1s steps(3) infinite;
}
@keyframes back {
	0% {
		transform: translateX(0%);
	}
	50% {
		transform: translateX(100%);
	}
	100% {
		transform: translateX(0);
	}
}

/* @media (max-width: 767px) {
	.wolf {
		animation-duration: 1.5s;
	}
} */
